import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useNavigate } from "react-router-dom";

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const myToken = localStorage.getItem("token");

  const navigate = useNavigate();

  useEffect(() => {
    if (!myToken) {
      navigate("/");
    }
  }, [myToken]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const sidebarWidth = isOpen ? "20%" : "5%";
  const mainContainerWidth = isOpen ? "80%" : "95%";

  return (
    <>
      <div className="layout-container">
        <div
          className={`  sidebar-container `}
          style={{ minWidth: sidebarWidth }}
        >
          <Sidebar
            isOpen={isOpen}
            toggleSidebar={toggleSidebar}
            sidebarWidth={sidebarWidth}
          />
        </div>
        <main
          className={` main-container `}
          style={{ minWidth: mainContainerWidth }}
        >
          <Topbar />
          <div className="mx-auto px-6 py-8">{children}</div>
        </main>
      </div>
    </>
  );
};

export default Layout;
