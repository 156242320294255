import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ChartComponent = ({ data = {}, weeklyLoading }) => {
  console.log("WeeklyOperations", data);
  const parseData = (tab) => {
    const tabData = data[tab.toLowerCase()] || {};
    const parsedData = [];

    const platforms = ["justeat", "uber", "deliveroo"];
    platforms.forEach((platform) => {
      (tabData[platform] || []).forEach((entry, index) => {
        if (!parsedData[index]) {
          parsedData[index] = {
            name: `${entry.startdate} - ${entry.enddate}`,
            startdate: entry.startdate,
            enddate: entry.enddate,
          };
        }
        parsedData[index][platform] = parseFloat(entry[tab.toLowerCase()]);
      });
    });

    const maxPoints = 8;
    platforms.forEach((platform) => {
      while (parsedData.length < maxPoints) {
        parsedData.push({ name: "", [platform]: undefined });
      }
    });

    parsedData.forEach((dataPoint) => {
      platforms.forEach((platform) => {
        if (dataPoint[platform] === undefined) {
          dataPoint[platform] = null;
        }
      });
    });

    return parsedData;
  };

  const [activeTab, setActiveTab] = useState("Sales");

  const getData = () => {
    let parsedData;
    switch (activeTab) {
      case "Sales":
        parsedData = parseData("sales");
        break;
      case "Operational Excellence":
        parsedData = parseData("operational");
        break;
      case "Ratings":
        parsedData = parseData("rating");
        break;
      case "Open Rates":
        parsedData = parseData("openrate");
        break;
      default:
        parsedData = parseData("sales");
        break;
    }

    // Reverse the parsed data so that dates appear in the correct order
    return parsedData.reverse();
  };

  const chartData = {
    labels: getData().map((item) => item.name),
    datasets: [
      {
        label: "Uber Eats",
        data: getData().map((item) => item.uber),
        fill: false,
        borderColor: "#008000",
        backgroundColor: "#008000",
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        borderDash: [5, 5],
        tension: 0.1,
        pointStyle: "circle",
        pointRadius: 50,
        spanGaps: false,
      },
      {
        label: "JustEat",
        data: getData().map((item) => item.justeat),
        fill: false,
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        borderColor: "#ec7a2f",
        backgroundColor: "#ec7a2f",
        tension: 0.1,
        pointStyle: "circle",
        pointRadius: 50,
        spanGaps: false,
      },
      {
        label: "Deliveroo",
        data: getData().map((item) => item.deliveroo),
        fill: false,
        borderColor: "#56bcf9",
        backgroundColor: "#56bcf9",
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        tension: 0.1,
        pointStyle: "circle",
        pointRadius: 50,
        spanGaps: false,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 20,
        bottom: 10,
        left: 0,
        right: 10,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.dataset.label || "";
            const value = tooltipItem.raw;
            return `${label}: ${value}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            if (activeTab === "Operational Excellence") {
              return value + "%";
            }
            return value >= 1000
              ? "£" + value / 1000 + "k"
              : "" + value.toFixed(1);
          },
        },
        min: activeTab === "Operational Excellence" ? 0 : undefined,
        max: activeTab === "Operational Excellence" ? 100 : undefined,
        stepSize: activeTab === "Operational Excellence" ? 10 : undefined,
        border: {
          display: false,
        },
      },
    },
  };

  const Label = ({ color, text }) => (
    <div className="flex items-center space-x-2">
      <span
        className={`w-[0.55rem] h-[0.55rem] rounded-full`}
        style={{ backgroundColor: color }}
      ></span>
      <span className="text-gray-700 text-sm">{text}</span>
    </div>
  );

  return (
    <section className="section dashboard">
      <div className="bg-white w-full p-3 rounded-lg shadow my-5 px-6 py-4">
        <div className="flex justify-between flex-wrap">
          <h2 className="text-xl font-semibold">Last 8 weeks operations</h2>
          <div className="flex space-x-4 text-[18px] leading-[22.5px]">
            {["Sales", "Operational Excellence", "Ratings", "Open Rates"].map(
              (tab) => (
                <button
                  key={tab}
                  className={`font-medium text-sm ${activeTab === tab
                      ? "border-b-2 border-black font-semibold"
                      : "text-primary-5 text-md font-medium"
                    }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
              )
            )}
          </div>
        </div>

        <div className="flex space-x-6 pt-4">
          <Label color="#008000" text="Uber Eats" />
          <Label color="#ec7a2f" text="JustEat" />
          <Label color="#56bcf9" text="Deliveroo" />
        </div>
        <div className="row">
          <div className="col-lg-12">
            {weeklyLoading ? (
              <div className="flex justify-center items-center h-96">
                <div className="animate-pulse space-y-4 w-full">
                  <div className="bg-gray-300 h-96 w-full"></div>
                </div>
              </div>
            ) : (
              <div className="App w-full">
                <Line
                  data={chartData}
                  options={chartOptions}
                  height={400}
                  width={800}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChartComponent;
