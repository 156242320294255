import React, { useEffect, useState } from "react";
import MultiCardCarousel from "../global-components/MultiCardCarousel";
import Pagination from "../global-components/Pagination";

import CustomTable from "../global-components/CustomTable";
import { LocationIcon, Motorcycle } from "../icons/index";
import TotalSales from "./TotalSales";
import AverageRating from "./AverageRating";
import { useCallPostMutation, useCallGetQuery } from "../../services/api";
import { useSelector } from "react-redux";

import CustomDropdownCheckbox from "../global-components/CustomDropdownChekbox";
import ParentDatePicker from "../global-components/CustomDatePicker/ParantDatePicker";
import ChartComponent from "../global-components/Charts";
import { useDispatch } from "react-redux";
import DrodownSkelton from "../skelaton/DrodownSkelton";
import { format } from "date-fns";
import {
  handleDateChange,
  setSelectedLocation,
} from "../../redux/slices/filter-slice";

import DatePickerSkeleton from "../skelaton/DatePickerSkelton";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import DailyOperationalScore from "../../domain/DailyOperations/components/DailyOperationalScore";
import DailyOperationsTable from "./DailyOperationsTable";
import NewAverageOperationalScore from "./NewAverageOperational";

export default function NewOverView() {
  const dispatch = useDispatch();
  const appliedFilter = useSelector((state) => state?.filter?.addFilter);
  const [reviewData, setReviewData] = useState([]);
  const [refundData, setRefundData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [weeklyOperationsData, setWeeklyOperationsData] = useState({});
  const [weeklyRollingRating, setWeeklyRollingRating] = useState({});
  const [weeklyRollingOPsScore, setWeeklyRollingOPsScore] = useState({});
  const [selectReview, setSelectReview] = useState(["all"]);
  const [selectRefund, setSelectRefund] = useState(["all"]);
  const [selectLocation, setSelectLocation] = useState(["all"]);
  const [reviewOptions, setReviewOptions] = useState([]);
  const [refundOptions, setRefundOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refundLoading, setRefundLoading] = useState(true);
  const [weeklyLoading, setWeeklyLoading] = useState(true);
  const weeklyDates = useSelector((state) => state.filter?.weeklyDates);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const [totalRecords, setTotalRecords] = useState(0);
  const [currentBatch, setCurrentBatch] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dateType, setDateType] = useState("weekly");

  const [searchParams] = useSearchParams();
  const redirectWeekly = searchParams?.get("redirect");

  const GetDropDownData = useCallGetQuery("new-weekly-operations/get-location");
  const ReduxSelectedLocations = useSelector(
    (state) => state.filter.selectedLocations
  );

  const handleFetchLocation = async () => {
    const { data } = GetDropDownData;
    console.log({ data123456: data })
    if (data?.status) {
      const location = data?.data?.map((item) => ({
        value: item.Name,
        label: item.Name,
      }));

      // Sort the location array alphabetically by label
      location.sort((a, b) => a.label.localeCompare(b.label));

      setLocationData(location);

      // Dispatch the sorted data if needed
      // dispatch(setweeklyOperationsData(location));
    }
  };

  const [postData, { isLoading: isPosting }] = useCallPostMutation();

  const handleFetchReviewData = async () => {
    try {
      setLoading(true);
      const result = await postData({
        url: "new-weekly-operations/get-weekly-oprations-review",
        body: {
          startDate: weeklyDates.start,
          endDate: weeklyDates.end,
          locations: ReduxSelectedLocations, //selectLocation.length === locationData.length ? ["all"] : (appliedFilter?.weeklyOperationsData?.length > 0 ? appliedFilter?.weeklyOperationsData : selectLocation),
          sort:
            reviewOptions?.length === selectReview?.length
              ? ["all"]
              : selectReview,
          page: currentBatch,
        },
      });
      if (result?.data?.status) {
        if (currentBatch > 1) {
          setReviewData((prevData) => [...prevData, ...result?.data?.data]);
        } else {
          setReviewData(result?.data?.data);
        }
        setTotalRecords(result?.data?.totalRecords);
        const newReviewOptions = [];
        if (result?.data?.check?.deliveroo) {
          newReviewOptions.push({ value: "deliveroo", label: "Deliveroo" });
        }
        if (result?.data?.check?.justeat) {
          newReviewOptions.push({ value: "justeat", label: "JustEat" });
        }
        if (result?.data?.check?.ubereats) {
          newReviewOptions.push({ value: "ubereats", label: "Uber Eats" });
        }
        setReviewOptions(newReviewOptions);
      }
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  //   setSelectedLocation
  const handleFetchRefundData = async () => {
    try {
      setRefundLoading(true);
      const result = await postData({
        url: "new-weekly-operations/get-weekly-oprations-refund",
        body: {
          startDate: weeklyDates.start,
          endDate: weeklyDates.end,
          deliveryApp:
            refundOptions?.length === selectRefund?.length
              ? ["all"]
              : selectRefund,
          locations: ReduxSelectedLocations, //selectLocation.length === locationData.length ? ["all"] : (appliedFilter?.weeklyOperationsData?.length > 0 ? appliedFilter?.weeklyOperationsData : selectLocation),
          itemsPerPage: itemsPerPage,
          page: currentPage,
        },
      });
      if (result?.data?.status) {
        setRefundData(result?.data?.refund);
        setTotalPage(result?.data?.totalPage);

        const newRefundOptions = [];
        if (result?.data?.data?.Deliveroo) {
          newRefundOptions.push({ value: "deliveroo", label: "Deliveroo" });
        }
        if (result?.data?.data?.Justeat) {
          newRefundOptions.push({ value: "justeat", label: "JustEat" });
        }
        if (result?.data?.data?.Ubereats) {
          newRefundOptions.push({ value: "ubereats", label: "Uber Eats" });
        }
        setRefundOptions(newRefundOptions);
      }
      setRefundLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  // const calculateDays = (start, end) => {
  //   // Use moment.js to parse the already formatted dates
  //   const startDate = moment(start, "YYYY-MM-DD");
  //   const endDate = moment(end, "YYYY-MM-DD");

  //   // Calculate the difference in days
  //   const differenceInDays = endDate.diff(startDate, "days");

  //   console.log("diffrence", differenceInDays);

  //   setDateType(differenceInDays === 6 ? "weekly" : "custom");
  // };

  // useEffect(() => {
  //   calculateDays(weeklyDates.start, weeklyDates.end);
  // }, [weeklyDates, selectLocation]);

  const handleFetchWeeklyOperationsData = async () => {
    try {
      setWeeklyLoading(true);

      // const startDate = moment(weeklyDates.start, "YYYY-MM-DD");
      // const endDate = moment(weeklyDates.end, "YYYY-MM-DD");
      // const differenceInDays = endDate.diff(startDate, "days");

      const result = await postData({
        url: "new-weekly-operations/get-weekly-oprations-data",
        // url: "weekly-operations/get-weekly-custom-range",
        body: {
          startDate: weeklyDates.start,
          endDate: weeklyDates.end,
          locations: ReduxSelectedLocations,
          // rangeType: differenceInDays === 6 ? "weekly" : "custom",
        },
      });

      const weeklyRollingRatings = await postData({
        url: "weekly-ops/get-rolling-ratings",
        body: {
          weekStart: weeklyDates.start
        },
      });
    
      if (weeklyRollingRatings?.data?.status) {
        if(!weeklyRollingRatings?.data?.data?.current_avg_rating){
          const startDate = new Date(weeklyDates.start);
          var previousDate = new Date(startDate.getTime() - 7 * 24 * 60 * 60 * 1000);
          previousDate = previousDate.toISOString().split('T')[0];
          console.error("Null", weeklyDates.start, previousDate)
          dispatch(handleDateChange({
            id: "weeklyDates",
            startDate: previousDate,
            endDate: weeklyDates.start,
          }))
        }else{
          setWeeklyRollingRating(weeklyRollingRatings?.data?.data);
        }
      }

      
      const weeklyRollingOPsScore = await postData({
        url: "weekly-ops/get-rolling-ops-score",
        body: {
          weekStart: weeklyDates.start
        },
      });
    
      if (weeklyRollingOPsScore?.data?.status) {
        setWeeklyRollingOPsScore(weeklyRollingOPsScore?.data?.data);
      }

      if (result?.data?.status) {
        setWeeklyOperationsData(result?.data?.data);
      }
      setWeeklyLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleSelectionChange = (selectedValues, dropdownType) => {
    if (dropdownType === "location") {
      setSelectLocation(selectedValues);
      dispatch(setSelectedLocation(selectedValues));
    }
  };

  useEffect(() => {
    handleFetchReviewData();
  }, [selectReview, weeklyDates, selectLocation, currentBatch]);

  useEffect(() => {
    handleFetchRefundData();
  }, [selectRefund, weeklyDates, selectLocation, currentPage, itemsPerPage]);

  useEffect(() => {
    handleFetchWeeklyOperationsData();
  }, [weeklyDates, selectLocation]);

  useEffect(() => {
    if (!GetDropDownData.isLoading) {
      handleFetchLocation();
    }
  }, [GetDropDownData.isLoading]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const resetCarousel = () => {
    setCurrentBatch(1);
    setCurrentIndex(0);
    setTotalRecords(null);
  };

  const handleAfterChange = (currentSlide) => {
    if (
      currentSlide + 16 >= reviewData.length &&
      reviewData.length < totalRecords
    ) {
      setCurrentBatch((prevBatch) => prevBatch + 1);
    }
  };
  const appliedFilterCount = useSelector(
    (state) => state?.filter?.appliedFilterCount
  );

  const { data: fetchInitialWeeklyData, isLoading } = useCallGetQuery(
    "weekly-operations/get-all-weekly-oprations-data"
  );

  const fetchInitialWeeklyDataFunction = () => {
    if (fetchInitialWeeklyData) {
      const formattedStartDate = fetchInitialWeeklyData?.data?.week_start || "";
      const formattedEndDate = fetchInitialWeeklyData?.data?.week_end || "";

      if (formattedStartDate && formattedEndDate) {
        const start = format(formattedStartDate, "yyyy-MM-dd") || "";
        const end = format(formattedEndDate, "yyyy-MM-dd") || "";
        dispatch(
          handleDateChange({
            id: "weeklyDates",
            startDate: start,
            endDate: end,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (!redirectWeekly) {
      fetchInitialWeeklyDataFunction();
    }
  }, [fetchInitialWeeklyData]);

  const onDateChange = (dateArray, isRange) => {

  }

  return (
    <div>
      <div className="flex justify-between items-center pb-5 flex-wrap">
        {isLoading ? (
          <DatePickerSkeleton />
        ) : (
          <ParentDatePicker id={"weeklyDates"} onDateChange={onDateChange} />
        )}
        {/* <div className="flex items-center space-x-2 ml">
          <LocationIcon />
          <span className="text-primary-6 text-base font-[600]">Location</span>
          {locationData.length > 0 ? (
            <CustomDropdownCheckbox
              options={locationData}
              onSelectionChange={(data) => {
                handleSelectionChange(
                  locationData.length === data.length ? ["all"] : data,
                  "location"
                );
              }}
              defaultSelectAll={ReduxSelectedLocations?.[0] === "all" ? 1 : 0}
              selectAllText={"All"}
              selectAllValue={"All"}
              dropdownType={"location"}
              width={137}
              placeholder={"Select..."}
              customBorderColor={"#A1AEBF"}
              customBorderRadius={2}
              customBorderWidth={2}
              menuHeight={200}
              menuWidth={160}
              left={"-25px"}
              activeValues={
                ReduxSelectedLocations?.[0] === "all"
                  ? locationData
                  : ReduxSelectedLocations
              }

            //   activeValues={appliedFilter?.weeklyOperationsData || []}
            // activeValues={["Winchester"]}
            />
          ) : (
            <div className="m-3">
              <DrodownSkelton />
            </div>
          )}
        </div> */}
      </div>

      <div className="">
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6">
          <TotalSales
            data={weeklyOperationsData?.sales}
            weeklyLoading={weeklyLoading}
          />
          <NewAverageOperationalScore
            data={weeklyRollingOPsScore}
            weeklyLoading={weeklyLoading}
          />
          <AverageRating
            data={weeklyRollingRating}
            weeklyLoading={weeklyLoading}
          />
        </div>
      </div>

      {/*Weeks Operations
        <ChartComponent
        data={weeklyOperationsData?.graph}
        weeklyLoading={weeklyLoading}
        />
        */}
      <DailyOperationsTable />




    </div>
  );
}
