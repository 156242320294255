import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  OverlayView
} from "@react-google-maps/api";
import { MapPin } from "lucide-react";
import ReactDOMServer from "react-dom/server";

import HoverTooltip from "./../../components/GoogleMap/HoverTooltip";
import ClickModal from "./../../components/GoogleMap/ClickModal";
import MapControls from "./../../components/GoogleMap/MapControls";
import { 
  DEFAULT_CENTER, 
  DEFAULT_ZOOM, 
  mapContainerStyle, 
  customMapStyles,
  RATING_THRESHOLDS,
  COLORS
} from "./../../components/GoogleMap/constants";
import { infoWindowStyles } from "./../../components/GoogleMap/styles";


// Utility functions for marker creation
const getRatingColor = (rating) => {
  if (rating >= RATING_THRESHOLDS.EXCELLENT) return COLORS.DARK_GREEN;
  if (rating >= RATING_THRESHOLDS.GOOD) return COLORS.LIGHT_GREEN;
  if (rating >= RATING_THRESHOLDS.AVERAGE) return COLORS.AMBER;
  return COLORS.RED;
};

const createCustomMarker = (rating) => {
  const color = getRatingColor(rating);
  const markerSvg = ReactDOMServer.renderToString(
    <MapPin color={color} size={30} fill="white" />
  );
  
  // Check if google.maps is available before using its constructors
  if (window.google && window.google.maps && window.google.maps.Size) {
    return {
      url: `data:image/svg+xml;base64,${btoa(markerSvg)}`,
      scaledSize: new window.google.maps.Size(30, 30),
      anchor: new window.google.maps.Point(0, 30),
    };
  }
  
  // Fallback if google.maps is not yet loaded
  return {
    url: `data:image/svg+xml;base64,${btoa(markerSvg)}`,
    scaledSize: { width: 30, height: 30 },
    anchor: { x: 15, y: 30 },
  };
};

const MainGoogleMap = () => {
  const markerPositions = useSelector((state) => state.filter.markerPositions);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [mapError, setMapError] = useState(null);
  const [map, setMap] = useState(null);
  const mapContainerRef = useRef(null);
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
  const [Markers, setMarkers] = useState([])

  useEffect(() => {
    const timer = setTimeout(() => {
      setMarkers(markerPositions);
    }, 1500); 
  
    return () => clearTimeout(timer);
  }, [markerPositions]);
  

  // Defined map options
  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: false,
    styles: customMapStyles,
    backgroundColor: '#fff',
    clickableIcons: false,
    gestureHandling: 'greedy',
    minZoom: 3,
    maxZoom: 18,
  };

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = infoWindowStyles;
    document.head.appendChild(styleElement);

    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    
    // Check for Google Maps API loading
    const checkGoogleMapsLoaded = () => {
      if (window.google && window.google.maps) {
        setIsGoogleMapsLoaded(true);
      } else {
        // Retry after a short delay
        setTimeout(checkGoogleMapsLoaded, 100);
      }
    };
    checkGoogleMapsLoaded();
    
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      styleElement.remove();
      if (map) {
        setMap(null);
      }
    };
  }, [map]);

  // Defined methods
  const handleMarkerClick = useCallback((marker) => {
    setHoveredMarker(null);
    setActiveMarker(activeMarker?.lat === marker.lat ? null : marker);
  }, [activeMarker]);

  const handleMarkerMouseOver = useCallback((marker) => {
    if (!activeMarker) {
      setHoveredMarker(marker);
    }
  }, [activeMarker]);

  const handleMarkerMouseOut = useCallback(() => {
    setHoveredMarker(null);
  }, []);

  const handleZoomIn = useCallback(() => {
    if (map) {
      map.setZoom(map.getZoom() + 1);
    }
  }, [map]);

  const handleZoomOut = useCallback(() => {
    if (map) {
      map.setZoom(map.getZoom() - 1);
    }
  }, [map]);

  const toggleFullscreen = useCallback(() => {
    if (!document.fullscreenElement) {
      mapContainerRef.current?.requestFullscreen().catch((err) => {
        console.error("Error enabling full-screen mode:", err);
        setMapError("Failed to enter fullscreen mode");
      });
    } else {
      document.exitFullscreen();
    }
  }, []);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  if (mapError) {
    return <div className="text-red-500">Error loading map: {mapError}</div>;
  }

  // Only render markers if Google Maps API is loaded
  const renderMarkers = isGoogleMapsLoaded && Markers?.map((position, index) => (
    <React.Fragment key={`marker-${position.lat}-${position.lng}-${index}`}>
      <Marker
        position={{ lat: position.lat, lng: position.lng }}
        onClick={() => handleMarkerClick(position)}
        onMouseOver={() => handleMarkerMouseOver(position)}
        onMouseOut={handleMarkerMouseOut}
        icon={createCustomMarker(position.rating)}
      />
      
      {hoveredMarker?.lat === position.lat && !activeMarker && (
        <OverlayView
          position={{ lat: position.lat, lng: position.lng }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={(width, height) => ({
            x: -(width / 2),
            y: -height - 40,
          })}
        >
          <HoverTooltip
            location={position.location}
            rating={position.rating}
          />
        </OverlayView>
      )}

      {activeMarker?.lat === position.lat && (
        <InfoWindow
          position={{ lat: position.lat, lng: position.lng }}
          onCloseClick={() => setActiveMarker(null)}
          options={{
            pixelOffset: new window.google.maps.Size(0, -30),
            disableAutoPan: false,
          }}
        >
          <ClickModal
            location={activeMarker.location}
            rating={activeMarker.rating}
            opScore={activeMarker.opScore}
          />
        </InfoWindow>
      )}
    </React.Fragment>
  ));

  return (
    <div ref={mapContainerRef} className="w-full shadow-md h-full relative">
      <LoadScript 
        googleMapsApiKey="AIzaSyAravivpxM8-uc_PTmb2reJ4bwGCc6J3Ik"
        onLoad={() => setIsGoogleMapsLoaded(true)}
        onError={() => setMapError("Failed to load Google Maps API")}
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={DEFAULT_CENTER}
          zoom={DEFAULT_ZOOM}
          options={mapOptions}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {renderMarkers}

          <MapControls
            onZoomIn={handleZoomIn}
            onZoomOut={handleZoomOut}
            onFullscreen={toggleFullscreen}
            isFullscreen={isFullscreen}
          />
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MainGoogleMap;