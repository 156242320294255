import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { useCallPostMutation } from "../../services/api"; // Assuming you have an API hook for this
import { GrowthIconWhite } from "../../components/icons"; // Adjust the import based on your project structure

const Verify = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [postData] = useCallPostMutation();
  const navigate = useNavigate();

  // Assuming you have the verification token available, e.g., from URL params
  const token = new URLSearchParams(window.location.search).get("token");

  useEffect(() => {
    const verifyEmail = async () => {
      setLoading(true);
      try {
        const response = await postData({
          url: "verify-email", // Adjust the URL based on your API
          body: { token }, // Pass the verification token
        }).unwrap();

        if (response?.status === true) {
          setMessage("Email verified successfully!");
          toast.success(response?.message);
        } else {
          setMessage("Email verification failed. Please try again.");
          toast.error(response?.message);
        }
      } catch (error) {
        setMessage("An unexpected error occurred. Please try again.");
        toast.error("An unexpected error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    // Call the verify email function
    if (token) {
      verifyEmail();
    } else {
      setMessage("Invalid verification link.");
    }
  }, [postData, token]);

  return (
    <>
      <ToastContainer />
      <section className="h-screen bg-white flex">
        <div className="relative w-[50%] h-screen bg-[#022c19]">
          <div className="absolute top-0 left-0 right-0 h-1/3 bg-gradient-to-b from-[#00FF85] to-[#17E080] opacity-30 rounded-full transform -translate-y-1/3 blur-3xl"></div>

          <img
            src="/burger.jpeg"
            alt="Burger"
            className="h-full w-full object-cover opacity-50"
          />

          {/* Text overlay */}
          <div className="absolute inset-0 flex items-center justify-center">
            <GrowthIconWhite />
          </div>
        </div>

        {/* Verification content */}
        <div className="w-full md:w-1/2 flex justify-center items-center">
          <div className="w-full max-w-md mt-5">
            <Typography variant="h2" align="center" className="m-6">
              Verify Your Email
            </Typography>

            <div className="w-full flex justify-center mt-4">
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "#061629",
                  borderRadius: "8px",
                  padding: "12px 16px",
                  marginLeft: "16px",
                  width: "auto",
                  height: "40px",
                }}
              >
                <span
                  className="Product_Sans_Medium font-[500]"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    textTransform: "none",
                  }}
                  onClick={() => navigate("/")}
                >
                  Back to Home
                  <svg
                    width="14"
                    height="11"
                    viewBox="0 0 14 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "8px" }}
                  >
                    <path
                      d="M13.0306 6.03122L8.53063 10.5312C8.38973 10.6721 8.19863 10.7513 7.99938 10.7513C7.80012 10.7513 7.60902 10.6721 7.46813 10.5312C7.32723 10.3903 7.24807 10.1992 7.24807 9.99997C7.24807 9.80072 7.32723 9.60962 7.46813 9.46872L10.6875 6.2506H1.5C1.30109 6.2506 1.11032 6.17158 0.96967 6.03093C0.829018 5.89028 0.75 5.69951 0.75 5.5006C0.75 5.30169 0.829018 5.11092 0.96967 4.97027C1.11032 4.82962 1.30109 4.7506 1.5 4.7506H10.6875L7.46937 1.5306C7.32848 1.3897 7.24932 1.19861 7.24932 0.999348C7.24932 0.80009 7.32848 0.608994 7.46937 0.468098C7.61027 0.327201 7.80137 0.248047 8.00062 0.248047C8.19988 0.248047 8.39098 0.327201 8.53187 0.468098L13.0319 4.9681C13.1018 5.03787 13.1573 5.12077 13.1951 5.21203C13.2329 5.3033 13.2523 5.40113 13.2522 5.49992C13.252 5.5987 13.2324 5.69649 13.1944 5.78767C13.1564 5.87885 13.1007 5.96162 13.0306 6.03122Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Verify;
