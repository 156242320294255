import React from "react";
import Skeleton from "react-loading-skeleton";
import { Growthicon, Declineicon } from "../icons";

const TotalSales = ({ data, weeklyLoading }) => {
  const calculatePercentageChange = (current, previous) => {
    const change = ((current - previous) / previous) * 100;
    return change > 100 ? 100 : change;
  };

  const calculatePercentageOfTotal = (platformSales, totalSales) => {
    return (platformSales / totalSales) * 100;
  };

  const salesData = [
    {
      name: "Deliveroo",
      keyname: "deliveroo",
      amount: parseFloat(data?.deliveroo?.totalSales || 0),
      previousweekSales: parseFloat(data?.deliveroo?.previousweekSales || 0),
      percentage: data?.deliveroo?.previousweekSales
        ? calculatePercentageChange(
            parseFloat(data?.deliveroo?.totalSales),
            parseFloat(data?.deliveroo?.previousweekSales)
          )
        : 0,
      imgSrc: "deliveroo.png",
    },
    {
      name: "JustEat",
      keyname: "justeat",
      amount: parseFloat(data?.justeat?.totalSales || 0),
      previousweekSales: parseFloat(data?.justeat?.previousweekSales || 0),
      percentage: data?.justeat?.previousweekSales
        ? calculatePercentageChange(
            parseFloat(data?.justeat?.totalSales),
            parseFloat(data?.justeat?.previousweekSales)
          )
        : 0,
      imgSrc: "justeat.png",
    },
    {
      name: "Uber Eats",
      keyname: "uber",
      amount: parseFloat(data?.uber?.totalSales || 0),
      previousweekSales: parseFloat(data?.uber?.previousweekSales || 0),
      percentage: data?.uber?.previousweekSales
        ? calculatePercentageChange(
            parseFloat(data?.uber?.totalSales),
            parseFloat(data?.uber?.previousweekSales)
          )
        : 0,
      imgSrc: "ubereats.png",
    },
  ];

  const totalSales = salesData
    .reduce((total, item) => total + item.amount, 0)
    .toFixed(2);
  const totalPreviousSales = salesData
    .reduce((total, item) => total + item.previousweekSales, 0)
    .toFixed(2);

  const percentageChange =
    totalPreviousSales && totalPreviousSales !== "0.00"
      ? calculatePercentageChange(
          parseFloat(totalSales),
          parseFloat(totalPreviousSales)
        )
      : totalSales === "0.00"
      ? 0
      : 100;

  const isGrowth = percentageChange >= 0;

  const formatPercentage = (percentage) => {
    return percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(2);
  };

  const displayPercentage = formatPercentage(Math.abs(percentageChange));

  return (
    <div className="bg-white px-4 py-2 rounded-lg shadow-md h-[14.2rem] m-1">
      {weeklyLoading ? (
        <>
          <div className="flex gap-1 items-center mb-4">
            <Skeleton width={100} height={24} />
            <Skeleton width={100} height={10} />
          </div>
          <div className="mt-4">
            {[...Array(3)].map((_, i) => (
              <div
                className="grid grid-cols-3 items-center gap-14 mb-4"
                key={i}
              >
                <div className="flex items-center col-span-1">
                  <Skeleton circle={true} width={24} height={24} />
                  <Skeleton width={80} className="ml-2" />
                </div>
                <div className="w-full bg-gray-200 rounded-full h-1.5 col-span-1"></div>
                <Skeleton width={80} height={16} className="col-span-1" />
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <h3 className="text-md font-medium  text-primary-5">Weekly Sales</h3>
          <div className="flex gap-1 items-center mt-[6px]">
            <p className="text-xl font-bold">£ {Math.round(totalSales).toLocaleString()}</p>

            {isGrowth ? <Growthicon /> : <Declineicon />}
            <p
              className={`font-semibold text-xs ${
                isGrowth ? "text-green-500" : "text-red-500"
              }`}
            >
              {isGrowth ? "+" : "-"}
              {displayPercentage}% vs. last week
            </p>
          </div>
          <div className="mt-4">
            {salesData.map((data) => (
              <div
                className="grid grid-cols-3 items-center gap-4 mb-4"
                key={data.name}
              >
                <div className="flex items-center col-span-1">
                  <img
                    src={data.imgSrc}
                    alt={data.name}
                    className="w-8 h-8 mr-2"
                  />
                  <span className="text-sm font-semibold">{data.name}</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-1.5 col-span-1 relative">
                  <div
                    className="bg-green-500 h-1.5 rounded-full absolute"
                    style={{
                      width: `${calculatePercentageOfTotal(
                        data.amount,
                        totalSales
                      )}%`,
                    }}
                  ></div>
                </div>
                <span className="font-semibold col-span-1">
                  £{parseInt(data.amount).toLocaleString()}
                </span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default TotalSales;
