import React, { memo } from 'react';
import { BsArrowsFullscreen, BsFullscreenExit } from "react-icons/bs";
import { Zoominicon, Zoomouticon } from "../icons";

const MapControls = memo(({ onZoomIn, onZoomOut, onFullscreen, isFullscreen }) => (
  <div className="absolute top-2 right-2 z-[1000] flex flex-row space-x-2">
    <button
      onClick={onZoomIn}
      className="p-2 bg-white rounded-lg shadow hover:bg-gray-100 transition-colors"
    >
      <Zoominicon />
    </button>
    <button
      onClick={onZoomOut}
      className="p-2 bg-white rounded-lg shadow hover:bg-gray-100 transition-colors"
    >
      <Zoomouticon />
    </button>
    <button
      onClick={onFullscreen}
      className="p-2 bg-white rounded-lg shadow hover:bg-gray-100 transition-colors"
    >
      {isFullscreen ? <BsFullscreenExit /> : <BsArrowsFullscreen />}
    </button>
  </div>
));

export default MapControls;