import React, { memo } from 'react';
import { Star } from "lucide-react";

const ClickModal = memo(({ location, rating, opScore }) => (
  <div className="bg-white rounded-lg">
    <div className="flex flex-col items-center justify-center">
      <div className="relative w-full">
        <img
          src="https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="Street Food"
          className="w-[300px] h-[200px] object-cover"
          loading="lazy"
        />
      </div>
      <div className="p-4 w-full">
        <div className="flex items-center gap-3 mb-2">
          <div className="flex items-center gap-1">
            <Star size={15} className="text-yellow-500" />
            <span className="text-sm font-medium">{rating}</span>
          </div>
          <span className="text-sm font-medium">Op Score: {opScore}</span>
        </div>
        <h3 className="text-lg font-semibold">{location}</h3>
      </div>
    </div>
  </div>
));

export default ClickModal;