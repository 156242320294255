import React, { useState, useEffect, useRef } from "react";
import { useCallPostMutation } from "../../services/api";
import { useSelector } from "react-redux";

// ... TableSkeleton component remains the same ...

const TableSkeleton = () => {
  const columns = 8; // 8 weeks
  const rows = 1;

  return (
    <table className="border-separate border-spacing-2 w-full">
      <tbody>
        {Array(rows)
          .fill(null)
          .map((_, i) => (
            <React.Fragment key={i}>
              {Array(4)
                .fill(null)
                .map((_, j) => (
                  <tr key={j} className="my-4 border-2">
                    <td className="h-6 bg-gray-200 rounded my-4 py-4"></td>
                    {Array(columns)
                      .fill(null)
                      .map((_, k) => (
                        <td key={k} className="h-5 bg-gray-200 rounded w-100"></td>
                      ))}
                  </tr>
                ))}
            </React.Fragment>
          ))}
      </tbody>
    </table>
  );
};




const MetricsTable = ({ currentPage, handleTotalcount, itemsPerPage }) => {
  const [locationsData, setLocationsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [pageSize, setPageSize] = useState(5);
  const [totalLocations, setTotalLocations] = useState(0);
  const [error, setError] = useState(null);
  const observerRef = useRef(null);
  const [lastElementRef, setLastElementRef] = useState(null);

  // Redux selectors
  const selectedLocations = useSelector(
    (state) => state.filter.selectedLocations
  );
  const {
    weeklyDates: { start },
    addFilter: { opscore, ratings, sales, status },
    search,
  } = useSelector((state) => state.filter);

  const [postApi] = useCallPostMutation();

  function getFormattedMetricValue(label, metric) {
    // console.log(label, metric);
    if (label === "Inaccurate orders" && !metric || metric === 0 || metric === 0.00 || metric === "0" || metric === "0.0" || metric === "0.00")
      return "0%";
    else if (label === "Rider waiting time" && !metric || metric === 0 || metric === 0.00 || metric === "0" || metric === "0.0" || metric === "0.00")
      return "0 min";
    else if (label === "Open rate" && !metric || metric === 0 || metric === 0.00 || metric === "0" || metric === "0.0" || metric === "0.00")
      return "0%";
    else if (label === "Rating" && !metric || metric === 0 || metric === 0.00 || metric === "0" || metric === "0.0" || metric === "0.00")
      return "0";

    switch (label) {
      case "Inaccurate orders":
        return `${parseFloat(metric).toFixed(0)}%`;
      case "Open rate":
        return `${metric}%`;
      case "Rating":
        return metric;
      case "Rider waiting time":
        return `${metric} min`;
      default:
        return `${metric}`;
    }
  }


  const METRICS = [
    { key: "rating", label: "Rating" },
    { key: "inaccurateOrders", label: "Inaccurate orders" },
    { key: "riderWaitingTime", label: "Rider waiting time" },
    { key: "openRate", label: "Open rate" },
  ];

  const fetchData = async (pageNumber, isReset = false) => {
    try {
      setLoading(true);
      setError(null);

      const response = await postApi({
        url: "new-weekly-operations/v2/new-weekly-operation",
        body: {
          page: pageNumber,
          limit: pageSize,
          startDate: start,
          locations: selectedLocations,
        },
      });

      if (!response.data?.status) {
        throw new Error(response.data?.message || "Failed to fetch data");
      }

      const { locations, pagination } = response.data.data;
      setTotalLocations(pagination.totalLocations);
      handleTotalcount(
        response?.data?.data?.pagination?.totalLocations,
        response?.data?.data?.pagination?.totalPages,
        pageNumber
      );
      if (isReset) {
        setLocationsData(locations);
      } else {
        setLocationsData((prevData) => {
          // Get existing location names
          const existingNames = new Set(prevData.map((loc) => loc.name));

          // Filter out any duplicate locations
          const newLocations = locations.filter(
            (loc) => !existingNames.has(loc.name)
          );

          return [...prevData, ...newLocations];
        });
      }

      setHasMore(pageNumber * pageSize < pagination.totalLocations);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to load data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Reset everything when filters change
  useEffect(() => {
    setPage(1);
    setLocationsData([]);
    setHasMore(true);
    fetchData(1, true);
  }, [
    selectedLocations,
    pageSize,
    start,
    opscore,
    ratings,
    sales,
    status,
    search,
  ]);

  // Infinite scroll observer
  useEffect(() => {
    if (loading) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 0.1 }
    );

    if (lastElementRef) {
      observer.observe(lastElementRef);
    }

    return () => {
      if (lastElementRef) {
        observer.unobserve(lastElementRef);
      }
    };
  }, [loading, hasMore, lastElementRef]);

  // Fetch more data when page changes
  useEffect(() => {
    if (page > 1) {
      fetchData(page);
    }
  }, [page]);

  const getMetricColor = (value, metric) => {
    // console.log({ value, metric });
    // if (value)
    // {
    //   if(value === 0 || value === 0.00 || value === "0" || value === "0.0" || value === "0.00") return 'bg-slate-100 text-black'; 
    // }    
    if (metric === "riderWaitingTime") {
      if (value < 5) return 'bg-green-100 text-black';
      else if (value >= 5 && value < 8) return 'bg-blue-100 text-black';
      else if (value >= 8) return 'bg-yellow-100 text-black';
      return 'bg-yellow-100 text-black';
    }
    if (metric === "inaccurateOrders") {
      if (value < 2) return "bg-green-100 text-black";
      else if (value <= 5 && value > 2) return 'bg-blue-100 text-black';
      else if (value > 5) return 'bg-yellow-100 text-black';
      return 'bg-yellow-100 text-black';
    }
    if (metric === "openRate") {
      if (value >= 95) return "bg-green-100 text-black";
      else if (value >= 90 && value <= 95) return 'bg-blue-100 text-black';
      else if (value < 90) return 'bg-yellow-100 text-black';
      return 'bg-yellow-100 text-black';
    }
    // if (!value || value === "0.0" || value === "0.00") return "bg-red-100 text-black";
    if (metric === "rating") {
      if (value >= 4.5 || value >= 90) return "bg-green-100 text-black";
      else if ((value >= 4 && value < 4.5) || (value >= 80 && value < 90))
        return "bg-blue-100 text-black";
      else if ((value < 4) || (value >= 70 && value < 80))
        return "bg-yellow-100 text-black";
      return "bg-yellow-100 text-black";
    } else {
      // const numValue = parseFloat(value);
      if (value >= 90) return "bg-green-100 text-black";
      else if (value >= 80 && value < 90) return "bg-blue-100 text-black";
      else if (value >= 70 && value < 80) return "bg-yellow-100 text-black";
      else if (value >= 60 && value < 70) return "bg-orange-100 text-black";
      else if (value >= 0 && value < 60) return "bg-red-100 text-black";
      return "bg-red-100 text-black";
    }
  };

  if (error) {
    return (
      <div className="w-full p-4 text-center">
        <div className="text-red-500">{error}</div>
        <button
          onClick={() => fetchData(1, true)}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Retry
        </button>
      </div>
    );
  }

  if (loading && locationsData.length === 0) {
    return <TableSkeleton />;
  }

  return (
    <>
      {/* <div className="overflow-x-auto"> */}
      {/* <table className="min-w-full border-collapse border">
          <tbody> */}
      {locationsData.map((location, locationIndex) => (
        <React.Fragment key={location.name}>
          <tr>
            <td>
              {" "}
              <div className="p-2  text-lg text-primary-7  font-semibold">
                {/* {location.name} */}
                {
                  location?.name?.split(" - ")[1]
                }
              </div>
            </td>
          </tr>
          {METRICS.map((metric, metricIndex) => {
            const isLastElement =
              locationIndex === locationsData.length - 1 &&
              metricIndex === METRICS.length - 1;

            return (
              <>
                <tr
                  key={`${location.name}-${metric.key}`}
                  ref={isLastElement ? setLastElementRef : null}
                >
                  <td
                    className="border p-2 sticky left-0 bg-white z-10"
                  // style={{ width: "370px" }}
                  >
                    <div className="p-2 roboto-font-table font-semibold">
                      {metric.label}
                    </div>
                  </td>
                  {location.metrics.map((weekMetric) => (


                    (weekMetric.inaccurateOrders != 0 && weekMetric.inaccurateOrders !== "0" && weekMetric.inaccurateOrders !== "0.0" && weekMetric.inaccurateOrders !== "0.00") ||
                      (weekMetric.openRate != 0 && weekMetric.openRate !== "0" && weekMetric.openRate !== "0.0" && weekMetric.openRate !== "0.00") ||
                      (weekMetric.rating != 0 && weekMetric.rating !== "0" && weekMetric.rating !== "0.0" && weekMetric.rating !== "0.00") ||
                      (weekMetric.riderWaitingTime != 0 && weekMetric.riderWaitingTime !== "0" && weekMetric.riderWaitingTime !== "0.0" && weekMetric.riderWaitingTime !== "0.00") ?
                      (
                        <td
                          // style={{ width: "265px" }}
                          key={`${weekMetric.weekCommencing}-${metric.key}`}
                          className={`border p-2 text-center ${getMetricColor(
                            weekMetric[metric.key],
                            metric.key
                          )}`}
                        > {getFormattedMetricValue(metric.label, weekMetric[metric.key])}
                        </td>
                      ) : (
                        <td
                          // style={{ width: "265px" }}
                          key={`${weekMetric.weekCommencing}-${metric.key}`}
                          className={`border p-2 text-center bg-slate-100 text-black`}
                        >
                          N/A
                        </td>
                      )


                  )
                  )
                  }
                </tr>
              </>
            );
          })}
        </React.Fragment>
      ))}
      {/* </tbody>
        </table>
      </div> */}
      {/* <TableSkeleton /> */}

      {loading && <TableSkeleton />}

      {!loading && !hasMore && (
        <tr className="text-center text-gray-500 mt-4">
          <td>No more locations to load</td>
        </tr>
      )}
    </>
  );
};

export default MetricsTable;
