import React, { useState } from "react";

export default function Host() {
  const [activeTab, setActiveTab] = useState("Overview");

  return (
    <>
        <div className="h-[500px] w-full flex items-center justify-center">
            <h1 className="font-bold text-3xl">Dashboard Coming Soon...</h1>
        </div>
    </>
  );
}