export const infoWindowStyles = `
  .gm-style-iw {
    padding: 0 !important;
    background: transparent !important;
  }
  
  .gm-style-iw-d {
    overflow: hidden !important;
    max-height: none !important;
    background: transparent !important;
  }
  
  .gm-style-iw-c {
    padding: 0 !important;
    max-height: none !important;
    border-radius: 8px !important;
    background: white !important;
  }
  
  

  .gm-style-iw-t {
    position: absolute;
  }

  .gm-style-iw-tc {
    position: absolute;
    top: -1px;
    transform: translateX(-50%);
    height: 12px;
    width: 25px;
    background: white;
    box-shadow: 0 1px 4px rgba(0,0,0,0.15);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }


  .gm-style-iw-ch{
    display:none !important;
  }
  .gm-ui-hover-effect {
      position:absolute !important;
    top: 12px !important;
    right: -4px !important;
    background: #fff !important;
    border-radius: 50% !important;
    margin: 6px !important;
    z-index: 1000;
  }

  /* Hide all closed InfoWindows and their arrows */
  .gm-style > div > div > div > div > div > div > div.gm-style-iw-tc:not(:has(+ div > div.gm-style-iw)) {
    display: none !important;
  }
`;

/*
export const infoWindowStyles = `
  .gm-style-iw {
    padding: 0 !important;
    background: transparent !important;
  }
  
  .gm-style-iw-d {
    overflow: hidden !important;
    max-height: none !important;
    background: transparent !important;
  }
  
  .gm-style-iw-c {
    padding: 0 !important;
    max-height: none !important;
    border-radius: 8px !important;
    background: transparent !important;
  }
  .gm-style-iw-ch{
    display:none !important;
  }
  .gm-ui-hover-effect {
      position:absolute !important;
    top: 12px !important;
    right: -4px !important;
    background: #fff !important;
    border-radius: 50% !important;
    margin: 6px !important;
    z-index: 1000;
  }
`;*/