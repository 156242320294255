export const RATING_THRESHOLDS = {
    EXCELLENT: 4.7,
    GOOD: 4.4,
    AVERAGE: 4.0,
  };
  
  export const COLORS = {
    DARK_GREEN: "#0C4E28",
    LIGHT_GREEN: "#12B366",
    AMBER: "#FFC107",
    RED: "#B30625",
  };
  
  export const DEFAULT_CENTER = { lat: 51.505, lng: -0.09 };
  export const DEFAULT_ZOOM = 5;
  
  export const mapContainerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "0.5rem",
  };
  
  // Map styling for vibrant look
  export const customMapStyles = [
    {
      featureType: "all",
      elementType: "geometry",
      stylers: [{ visibility: "on" }]
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        { color: "#a1c4fd" },
        { lightness: 35 }
      ]
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        { color: "#f5f5f5" }
      ]
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        { color: "#ffffff" }
      ]
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        { color: "#e8f0e3" }
      ]
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [
        { color: "#f2f2f2" }
      ]
    }
  ];