import React, { memo } from 'react';
import { Star } from "lucide-react";

const HoverTooltip = memo(({ location, rating }) => (
  <div className="bg-white rounded-lg shadow-md p-2 min-w-[150px]">
    <div className="flex items-center gap-2">
      <Star size={12} className="text-yellow-500" />
      <span className="text-sm font-medium">{rating}</span>
    </div>
    <p className="text-sm font-medium mt-1">{location}</p>
  </div>
));

export default HoverTooltip;