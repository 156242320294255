import React from 'react';
import { MapPin } from "lucide-react";
import ReactDOMServer from "react-dom/server";
import { RATING_THRESHOLDS, COLORS } from './constants';

export const getRatingColor = (rating) => {
  if (rating >= RATING_THRESHOLDS.EXCELLENT) return COLORS.DARK_GREEN;
  if (rating >= RATING_THRESHOLDS.GOOD) return COLORS.LIGHT_GREEN;
  if (rating >= RATING_THRESHOLDS.AVERAGE) return COLORS.AMBER;
  return COLORS.RED;
};

export const createCustomMarker = (rating) => {
  const color = getRatingColor(rating);
  const markerSvg = ReactDOMServer.renderToString(
    <MapPin color={color} size={30} fill="white" />
  );

  return {
    url: `data:image/svg+xml;base64,${btoa(markerSvg)}`,
    scaledSize: new window.google.maps.Size(30, 30),
    anchor: new window.google.maps.Point(15, 30),
  };
};