import Button from "../../components/global-components/Button";
import AddFilters from "../../components/global-components/AddFilters";
import Searchbar from "../../components/global-components/Searchbar";
import { Table } from "../../components/Table";
import Pagination from "../../components/global-components/Pagination";
import { useState, useEffect } from "react";
import Map from "../../components/Map";
import { useSelector, useDispatch } from "react-redux";
import ParentDatePicker from "../../components/global-components/CustomDatePicker/ParantDatePicker";
import { useCallGetQuery, useCallPostMutation } from "../../services/api";
import { LocationIconGreen } from "../../components/icons";
import LoadingLoader from "../../components/global-components/LoadingLoader/LoadingLoader";
import {
  addMarkerPosition,
  setFilterItemPerPage,
  setFilterTotalPage,
  clearAllFilters,
  handleDateChange,
} from "../../redux/slices/filter-slice";
import { format } from "date-fns";
import DatePickerSkeleton from "../../components/skelaton/DatePickerSkelton";
import moment from "moment/moment";
import MainGoogleMap from "../../components/GoogleMap/MainGoogleMap";

const Locations = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateType, setDateType] = useState("weekly");
  const dispatch = useDispatch();

  const [postApi] = useCallPostMutation();
  const {
    locationDates: { start, end } = {},
    addFilter: { opscore, ratings, sales, status } = {},
    appliedFilterCount,
    search = "",
    totalpages = 0,
  } = useSelector((state) => state.filter) || {};

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);

    dispatch(setFilterItemPerPage(newItemsPerPage));
    setCurrentPage(1);
  };

  const { data: fetchInitialLocationData, isLoading } = useCallGetQuery(
    "weekly-operations/get-all-location-data"
  );

  const fetchInitialLocationDataFunction = () => {
    if (fetchInitialLocationData) {
      const formattedStartDate =
        fetchInitialLocationData?.data?.week_start || "";
      const formattedEndDate = fetchInitialLocationData?.data?.week_end || "";

      if (formattedStartDate && formattedEndDate) {
        const start = format(formattedStartDate, "yyyy-MM-dd") || "";
        const end = format(formattedEndDate, "yyyy-MM-dd") || "";

        dispatch(
          handleDateChange({
            id: "locationDates",
            startDate: start,
            endDate: end,
          })
        );
      }
    }
  };

  useEffect(() => {
    fetchInitialLocationDataFunction();
  }, [fetchInitialLocationData]);

  const calculateDays = (start, end) => {
    // Use moment.js to parse the already formatted dates
    const startDate = moment(start, "YYYY-MM-DD");
    const endDate = moment(end, "YYYY-MM-DD");

    // Calculate the difference in days
    const differenceInDays = endDate.diff(startDate, "days");

    if (differenceInDays === 7) {
      setDateType("weekly");
    } else {
      setDateType("custom");
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const payload = {
        startDate: start,
        endDate: end,
        dateType: dateType === "custom" ? "custom" : "weekly",
        page: currentPage,
        pageSize: itemsPerPage,
        totalPages: totalpages || 3,
        search,
        op_score:
          opscore.length === 9 ? ["all"] : opscore.length > 0 ? opscore : [],
        rating:
          ratings.length === 10 ? ["all"] : ratings.length > 0 ? ratings : [],
        sales: sales.length === 3 ? ["all"] : sales.length > 0 ? sales : [],
        status: status.length === 3 ? ["all"] : status,
      };

      const response = await postApi({
        url: "weekly-operations/get-location-data",
        body: payload,
      });

      const responseData = response?.data?.data || [];

      setTableData(responseData);

      const coordinates = responseData
        .filter((el) => el?.longitude != null && el?.latitude != null) // Ensure both longitude and latitude are present
        .map((el) => ({
          lng: el?.longitude,
          lat: el?.latitude,
          location: el.location,
          status: el.status,
          opScore: el.op_score,
          rider_waiting_time: el?.overall_rider_Waiting_time,
          open_rate: el.overall_openrate,
          inaccurate_order: el.inaccurate_order,
          rating: el.rating,
        }));

      dispatch(addMarkerPosition(coordinates));

      dispatch(setFilterTotalPage(response?.data?.totalPages));
    } catch (error) {
      console.error("Error fetching data:", error);
      setTableData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    start,
    end,
    search,
    postApi,
    currentPage,
    itemsPerPage,
    totalpages,
    dispatch,
    appliedFilterCount,
  ]);

  const initialStartDate = useSelector(
    (state) => state.filter?.["locationDates"]?.start // || formattedCurrentStart
  );
  const initialEndDate = useSelector(
    (state) => state.filter?.["locationDates"]?.end // || formattedCurrentEnd
  );
  useEffect(() => {
    dispatch(
      clearAllFilters({
        id: "locationDates",
        startDate: initialStartDate,
        endDate: initialEndDate,
      })
    );
  }, []);
  const onDateChange = (dateArray, isRange) => {
    console.log({ dateArray, isRange });
  };

  return (
    <>
      <div className="w-full relative" style={{ height: "80vh" }}>
        <div className="flex flex-col sm:flex-row items-center gap-4 w-full">
          <div className="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden rounded-full bg-gray-900">
            <span className="font-medium text-gray-600">
              <LocationIconGreen />
            </span>
          </div>

          <div className="flex flex-col flex-grow">
            <span className="text-lg text-primary-7 font-semibold">
              My locations
            </span>
            <span className="text-sm font-medium text-primary-5">
              View and Manage all active host kitchens
            </span>
          </div>

          {/* <div className="flex items-end -mt-4">
            <Button />
          </div> */}
        </div>

        <div className="flex flex-col sm:flex-row items-center justify-between mt-2 gap-4">
          {isLoading ? (
            <div>
              <DatePickerSkeleton />
            </div>
          ) : (
            <ParentDatePicker
              id={"locationDates"}
              onDateChange={onDateChange}
            />
          )}

          <div className="flex flex-col sm:flex-row items-center gap-4 w-full sm:w-auto flex-wrap">
            <Searchbar />
            <AddFilters
              modalbuttonname={"Add filters"}
              handleApply={() => {
                fetchData();
              }}
            />
            <Pagination
              totalPages={totalpages}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          </div>
        </div>

        <div
          className="flex flex-col lg:flex-row mt-4 lg:mt-4 gap-4"
          style={{ height: "69vh", justifyContent: "space-around" }}
        >
          <div className="w-full lg:w-3/3 custom-scrollbar overflow-auto">
            <Table tableData={tableData} loading={loading} />
          </div>
          <div className="w-full lg:w-1/3 h-auto mt-4 lg:mt-0">
            <div className="h-full sm:h-full">
              {/* <Map /> */}
              <MainGoogleMap />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Locations;
